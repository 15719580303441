@import "../../import.scss";

.Contact {
  .contactInner {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;
    .contactContent {
      display: flex;
      flex-direction: column;
    }
    .contactTitle {
      font-size: 200%;
      font-weight: bold;
      margin-bottom: 4px;
    }
    .contactSub {
      color: #666;
      margin-bottom: 24px;
      a {
        color: $orange;
      }
    }
  }
  .label {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 80%;
    input,
    textarea {
      margin-top: 4px;

      outline: none;
      border: none;
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 4px;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 120%;
      &:focus {
        border-color: $orange;
      }
    }
    input {
      height: 30px;
    }
    textarea {
      min-height: 80px;
    }
    margin-bottom: 12px;
  }
  .contactSubmit {
    all: unset;
    display: block;
    background-color: $orange;
    text-align: center;
    color: white;
    font-weight: bold;
    height: 40px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .optionSelector {
    margin-top: 4px;

    overflow: hidden;
    .option {
      height: 30px;
      display: flex;
      align-items: center;
      padding-left: 8px;
      border-radius: 6px;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }
      &.active {
        svg {
          color: orange;
        }
        &:hover {
        }
      }
      &:hover {
        background-color: lighten($orange, 40);
      }
    }
  }
  .contactSuccess {
    text-align: center;
    font-weight: bold;
    margin-top: 12px;
  }
}
