@import "./import.scss";

.flex1 {
  flex: 1;
}
.invisibleAnchor {
  visibility: hidden;
  height: 1px;
  width: 1px;
  display: block;
}
