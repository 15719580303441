@import "../../import.scss";

.Home {
  display: flex;
  flex-direction: column;
  .catcherText {

    
    font-size: 170%;
    
    text-align: center;
    
    color:#222;
    background-color: #222;
    color:white;
    padding: 50px;
    letter-spacing: 0.8px;
  }
  .heroContent {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 120px;
    .heroLine {
      font-size: 200%;
      text-transform: uppercase;
      &.orange {
        font-size: 300%;
      }
    }
    .heroContentInnter {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    @include md {
      padding-left: 20%;
      flex: 0.3;
      align-items: start;
      text-align: left;
      .heroContentInnter {
        display: block;
      }
      .heroLine {
        font-size: 300%;
        @include xl {
          font-size: 400%;
          flex: 0.6;
          &.orange {
            font-size: 500%;
          }
        }
      }
    }
  }
}
