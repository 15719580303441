@import "../../import.scss";
.desktopHeader {
  display: none;

  @include md {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-size: 300%;
    font-weight: bold;
  }
  .sub {
    font-size: 300%;
    font-family: "Permanent Marker";
  }
}
.desktopButton {
  display: none;
  @include md {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.TrippleArea {
  @include md {
    display: flex;
  }
  .Section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100vh;
    scroll-snap-align: start;
    justify-content: center;
    flex: 1;
    @include md {
      scroll-snap-align: none;
      height: auto;
    }
    .title {
      font-size: 180%;
      font-weight: bold;
      @include md {
        display: none;
      }
    }
    .sub {
      font-size: 180%;
      font-family: "Permanent Marker";
      @include md {
        display: none;
      }
    }
    .icon {
      img {
        width: 160px;
      }
    }
    .step {
      display: flex;
      align-items: center;
      font-weight: bold;
      gap: 12px;
      .num {
        font-size: 400%;
      }
      .name {
        white-space: pre-wrap;

        font-size: 150%;
      }
    }
    .text {
      margin-left: 48px;
      margin-right: 48px;
      max-width: 400px;
    }
    .button {
      @include md {
        display: none;
      }
    }
  }
}
