@import "../../import.scss";

.Button {
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  padding-right: 12px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  transition: border-color 0.5s, background-color 0.5s, box-shadow 0.5s;
  font-weight: bold;
  &.large {
    height: 40px;
    border-radius: 20px;
    font-size: 150%;
    padding-left: 20px;
    padding-right: 20px;
  }
  &:not(.border):hover {
    box-shadow: 0px 0px 5px 0px #22222233;
  }
  &.filled {
    background-color: $orange;
    color: $white;
  }
  &.white {
    background-color: white;
  }
  &.border {
    border: 1px solid $orange;
    &:hover {
      background-color: lighten($orange, 40%);
    }
  }
}
