@import "../../import.scss";

.Nav {
  display: flex;
  align-items: center;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  border-bottom: 1px solid $orange;
  background-color: #f2f2f2dd;
  backdrop-filter: blur(10px);
  font-size: 80%;
  a {
    color: inherit;
    text-decoration: none;
  }
  &.desktopNav {
    display: none;
    @include md {
      display: flex;
    }
  }
  &.mobileNav {
    display: flex;

    @include md {
      display: none;
    }
    .mobileNavButton {
      font-size: 200%;

      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  padding-right: 12px;
  padding-left: 12px;
  .logo {
    font-weight: bold;
    font-size: 120%;
  }
  .centerNav {
    flex: 1;
    display: flex;
    justify-content: center;
    gap: 12px;
  }
  .rightNav {
    display: flex;
    gap: 12px;
  }

  @include md {
    font-size: 100%;
    padding-right: 24px;
    padding-left: 24px;
    .logo {
    }
    .centerNav {
      gap: 24px;
    }
    .rightNav {
      gap: 24px;
    }
  }
}

.mobileNavOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  @include md {
    display: none;
  }
  .Nav {
    position: static;
    background-color: #f2f2f2;
  }
  .mobileNavItem {
    text-align: center;

    margin-left: 24px;
    margin-right: 24px;
    text-transform: uppercase;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #444;
    color: inherit;
    font-size: 200%;
    @include sm {
      font-size: 300%;
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
  }
}
