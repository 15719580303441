@import "../../import.scss";

.PhoneBox {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  scroll-snap-align: start;
  @include md {
    flex-direction: row-reverse;
    scroll-snap-align: unset;
  }
  .top {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    @include md {
      align-items: flex-start;
      text-align: left;
      font-size: 140%;
    }
    margin-left: 24px;
    margin-right: 24px;
    .head {
      text-transform: uppercase;
    }
    .title {
      font-weight: bold;
      font-size: 200%;
    }
    .text {
      max-width: 400px;
    }
  }
  .desktopDemoButton {
    display: none;
    @include md {
      display: block;
    }
  }
  .mobileDemoButton {
    @include md {
      display: none;
    }
  }

  .bottom {
    flex: 3;
    @include md {
      flex: 2;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .phoneImage {
      max-width: 200px;
      max-height: 300px;
      @include md {
        max-width: 300px;
        max-height: 500px;
      }
    }
  }
  .intersector {
    position: absolute;
    bottom: 30%;
  }
}
