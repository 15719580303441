@import "../../import.scss";

.Hero {
  height: 100vh;

  &.desktopHero {
    display: none;
    @include md {
      display: flex;
    }
  }
  &.mobileHero {
    display: flex;
    scroll-snap-align: start;
    @include md {
      display: none;
    }
  }

  &.bg {
    background-size: cover;
    background-position: 50% 0%;
  }

  flex-direction: column;
  position: relative;
  .bottomRef {
    position: absolute;
    bottom: 30%;
  }
}
