@import "../../import.scss";

.PriceArea {
  display: flex;
  flex-direction: column;

  background-color: #222;
  color: $white;
  align-items: center;
  @include md {
    min-height: 100vh;
  }

  .title {
    font-size: 200%;
    font-weight: bold;
    text-align: center;
  }
  .sub {
    font-size: 180%;
    font-family: "Permanent Marker";
    text-transform: uppercase;
    text-align: center;
  }
  .prices {
    @include md {
      display: flex;
    }
  }
  .PriceBox {
    min-width: 60vw;
    border: 2px solid $white;
    border-radius: 12px;
    font-size: 120%;

    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-width: 80%;
    min-width: 350px;
    @include md {
      flex: 1;
      width: 300px;
      margin-left: 12px;
      margin-right: 12px;
    }
    .bar {
      background-color: $orange;
      width: 100%;
      color: $black;
      text-align: center;
      font-size: 150%;
      font-weight: bold;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .hiddenBar {
      height: 60px;
    }
    .title {
      text-transform: uppercase;
    }
    .price {
      font-weight: bold;
      font-size: 400%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .currency {
        font-size: 30%;
        font-weight: 400;
        padding-bottom: 12px;
      }
      .pricePre,
      .priceAfter {
        font-weight: 400;
        font-size: 30%;
        padding-bottom: 12px;
      }
    }
    .featureTitle {
      font-weight: bold;
      font-size: 150%;
    }
    .features {
      display: flex;
      flex-direction: column;
      .feature {
        display: flex;
        gap: 8px;
        .icon {
          color: $orange;
        }
      }
    }
    .button {
      .Button {
        color: white;
      }
    }
  }
}
